import { CtaType } from '../../types/types'
import SectionHeading from './SectionHeading'
import React from 'react'
import axios, { AxiosError } from 'axios'
import { useMutation } from '@tanstack/react-query'

type ContactFormType = {
    content?: CtaType
}
type ContactFormDataType = {
    name: string
    phone: string
    email: string
    topic: string
    message: string
    callback: string
}

type ErrorResponseData = {
    code: number
    success: boolean
    message?: string
    DisplayMessage?: string
}

const sendContactForm = async (formData: ContactFormDataType) => {
    return await axios({
        method: 'post',
        url: `${process.env.REACT_APP_MAIL_URL}`,
        data: formData,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Auth': process.env.REACT_APP_MAIL_KEY,
        },
    })
}

function SectionContactForm({ content }: ContactFormType) {
    const { mutate, isSuccess, isError, error } = useMutation({
        mutationKey: ['contactForm'],
        mutationFn: sendContactForm,
        onSuccess: () => console.log('Form sent successfully'),
        onError: (err: AxiosError) => console.log('Form failed to send', err),
    })

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isSuccess) return
        const formData: ContactFormDataType = {
            name: (e.target as any).name.value,
            phone: (e.target as any).phone.value,
            email: (e.target as any).email.value,
            topic: (e.target as any).subject.value,
            message: (e.target as any).message.value,
            callback: (e.target as any).callback.checked ? 'Ja' : 'Nein',
        }
        mutate(formData)
    }

    return (
        <div className="max-w-page mx-auto mb-24">
            <SectionHeading
                heading={content?.heading}
                subheading={content?.subheading}
            />
            <div className="p-4 max-w-4xl mx-auto">
                <div className="bg-blue_dark rounded-lg shadow-2xl px-8 py-4 text-sm font-medium text-primary">
                    <form
                        className="flex flex-col sm:space-y-8"
                        onSubmit={onSubmit}
                        autoComplete="true">
                        {/* Row 1: Name, Telefonnummer */}
                        <div className="flex flex-col sm:flex-row gap-4 mb-4 space-y-4 sm:space-y-0">
                            <div className="flex flex-col sm:w-1/2">
                                <label className="mb-2" htmlFor="name">
                                    Name
                                </label>
                                <input
                                    id="name"
                                    name="name"
                                    type="text"
                                    className={'rounded-lg p-4 text-content'}
                                    required
                                />
                            </div>
                            <div className="flex flex-col sm:w-1/2">
                                <label className="mb-2" htmlFor="phone">
                                    Telefonnummer
                                </label>
                                <input
                                    name="phone"
                                    id="phone"
                                    type="text"
                                    className={'rounded-lg p-4 text-content'}
                                    required
                                />
                            </div>
                        </div>

                        {/* Row 2: Email */}
                        <div className="flex flex-col mb-4">
                            <label className=" mb-2" htmlFor="email">
                                Email
                            </label>
                            <input
                                name="email"
                                id="email"
                                type="email"
                                className={'rounded-lg p-4 text-content'}
                                required
                            />
                        </div>

                        {/* Row 3: Betreff */}
                        <div className="flex flex-col mb-4">
                            <label className="mb-2" htmlFor="subject">
                                Betreff
                            </label>
                            <input
                                name="subject"
                                id="subject"
                                type="text"
                                className={'rounded-lg p-4 text-content '}
                                required
                            />
                        </div>

                        {/* Row 4: Nachricht */}
                        <div className="flex flex-col mb-4">
                            <label className="mb-2" htmlFor="message">
                                Nachricht
                            </label>
                            <textarea
                                id="message"
                                name="message"
                                className={
                                    'rounded-lg p-4 text-content h-32'
                                }></textarea>{' '}
                            {/* Adjust height as needed */}
                        </div>

                        {/* Row 5: Radio Buttons */}
                        <div className="flex gap-4 mb-4">
                            <div className="flex w-1/2 items-center gap-2">
                                <input
                                    type="checkbox"
                                    id="callback"
                                    name="callback"
                                    className={'form-checkbox h-4 w-4'}
                                />
                                <label
                                    htmlFor="callback"
                                    className="text-primary">
                                    Rückrufbitte (Optional)
                                </label>
                            </div>
                            <div className="flex w-1/2 justify-center items-center gap-2">
                                <input
                                    disabled={isSuccess}
                                    type="checkbox"
                                    id="dataUsage"
                                    className={'form-checkbox h-4 w-4'}
                                    required
                                />
                                <label
                                    htmlFor="dataUsage"
                                    className="text-primary">
                                    Datennutzung (Pflichtfeld)
                                </label>
                            </div>
                        </div>

                        {/* Submit Button */}
                        {isError && (
                            <span className="text-red-600 text-center">
                                {
                                    (error.response?.data as ErrorResponseData)
                                        .DisplayMessage
                                }
                            </span>
                        )}
                        {isSuccess && (
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-16 w-16 mx-auto text-orange_dark"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round">
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                <path d="M9 12l2 2l4 -4" />
                            </svg>
                        )}
                        {!isSuccess && (
                            <button
                                type="submit"
                                className="bg-orange_dark hover:bg-orange_light text-white text-xl rounded-lg p-4 font-permanentMarker">
                                Absenden
                            </button>
                        )}
                    </form>
                </div>
            </div>
        </div>
    )
}

export default SectionContactForm
