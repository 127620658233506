import React from 'react'
import { ReactComponent as WellenOben } from '../../assets/wellen_oben.svg'
import { ReactComponent as WellenUnten } from '../../assets/wellen_unten.svg'
import { ReactComponent as WellenBlauOben } from '../../assets/wellen_blau_oben.svg'
import SectionHeadingWhite from './SectionHeadingWhite'
import { ImageType } from '../../types/types'
import { API } from '../../data/constants'
import { useOnScreen } from '../common/smoothInEffektHook'
import { motion } from 'framer-motion'

type teacherData = {
    content?: {
        imageTeacher: {
            data: ImageType
        }
        heading: string
        lehrer: {
            id: number
            klasse: string
            lehrer: string
            tooltip: string
        }[]
        mitarbeiter: {
            id: number
            name: string
            stelle: string
        }[]
    }
}

function SectionTeachers({ content }: teacherData) {
    const [ref1, isVisible1] = useOnScreen({ threshold: 0.5 })
    const [ref2, isVisible2] = useOnScreen({ threshold: 0.5 })

    return (
        <div id="teachers" className=" bg-blue_dark ">
            <div className=" bg-cover relative h-[300px] sm:h-[500px] md:h-[700px] xl:h-[900px] overflow-hidden">
                <div className="absolute top-0 w-full z-10">
                    <WellenUnten />
                </div>
                <img
                    src={API.img + content?.imageTeacher?.data?.attributes?.url}
                    alt="bild der lehrer"
                    className="absolute inset-0 w-full h-full object-cover z-0  2xl:translate-y-4 2xl:h-[1200px] "
                />
                <div className="absolute -bottom-2 w-full">
                    <WellenBlauOben />
                </div>
            </div>
            <SectionHeadingWhite heading="Unsere Lehrerinnen" />
            <motion.div
                ref={ref1}
                initial={{ opacity: 0, y: 50 }}
                animate={isVisible1 && { opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="max-w-page mx-auto px-8 pb-4 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                {content?.lehrer.map((teacher) => (
                    <div
                        key={teacher.id}
                        className="container-tooltip flex gap-4 rounded-lg bg-primary py-2 px-4 items-center relative ">
                        <div className="rounded-full bg-orange_light px-3 py-2 text-primary">
                            {teacher.klasse}
                        </div>
                        <div>{teacher.lehrer}</div>
                        <div
                            className={`tooltip invisible ${
                                teacher.tooltip ? '' : 'hidden'
                            } px-4 py-1 z-10 absolute bottom-[105%] left-1/2 -translate-x-1/2 bg-content rounded-md text-white flex items-center gap-2 w-full`}>
                            {teacher.tooltip}
                        </div>
                    </div>
                ))}
            </motion.div>

            <motion.div
                ref={ref2}
                initial={{ opacity: 0, y: 50 }}
                animate={isVisible2 && { opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="max-w-page mx-auto px-8 pb-8 flex flex-col flex-wrap  md:flex-row justify-center gap-4">
                {content?.mitarbeiter.map((teacher) => (
                    <div
                        key={teacher.id}
                        className="flex gap-4 rounded-lg bg-primary py-2 px-4 items-center justify-center">
                        <div className="py-2">{teacher.name}</div>
                        <div className="rounded-full bg-orange_light p-2"></div>
                        <div>{teacher.stelle}</div>
                    </div>
                ))}
            </motion.div>
            <WellenOben />
        </div>
    )
}

export default SectionTeachers
