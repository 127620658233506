import { motion } from 'framer-motion'
import { useOnScreen } from '../common/smoothInEffektHook'
import { BlocksContent, BlocksRenderer } from '@strapi/blocks-react-renderer'

type SectionHeadingType = {
    heading?: string
    subheading?: string
    subheadingAsText?: BlocksContent
    id?: string
}

function SectionHeading({
    heading,
    subheading,
    id,
    subheadingAsText,
}: SectionHeadingType) {
    const [ref, isVisible] = useOnScreen({ threshold: 0.5 })
    const [ref2, isVisible2] = useOnScreen({ threshold: 0.5 })

    return (
        <div className="max-w-page mx-auto py-8 pb-4 sm:pb-8 flex flex-col justify-center text-center space-y-6 pt-16 md:pt-32">
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: 50 }}
                animate={
                    isVisible && {
                        opacity: 1,
                        y: 0,
                    }
                }
                transition={{ duration: 0.5 }}
                className="w-fit mx-auto space-y-6">
                <div
                    id={id}
                    className="font-permanentMarker text-3xl sm:text-5xl text-content">
                    {heading || ''}
                </div>
                <div className="flex justify-center flex-col w-4/5 mx-auto space-y-2">
                    <div className="flex">
                        <div className="w-2/5"></div>
                        <div className="h-1 w-full bg-orange_dark rounded-full"></div>
                    </div>
                    <div className="flex">
                        <div className="h-1 w-full bg-blue_dark rounded-full"></div>
                        <div className="w-2/5"></div>
                    </div>
                </div>
            </motion.div>
            <motion.div
                ref={ref2}
                initial={{ opacity: 0, y: 50 }}
                animate={
                    isVisible2 && {
                        opacity: 1,
                        y: 0,
                    }
                }
                transition={{ duration: 0.5 }}
                className="text-content px-8">
                {subheadingAsText ? (
                    <BlocksRenderer content={subheadingAsText ?? []} />
                ) : (
                    <p>{subheading || ''}</p>
                )}
            </motion.div>
        </div>
    )
}

export default SectionHeading
