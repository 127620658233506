import { useEffect, useRef, useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import { API } from '../../data/constants'
import { Article, Zeitung } from '../../types/types'
import SectionHeading from './SectionHeading'
import PopupArticle from './PopupArticle'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import { useOnScreen } from '../common/smoothInEffektHook'
import { motion } from 'framer-motion'

type ZeitungenWrapperType = {
    id?: string
    content?: Zeitung
    isImageFirst?: boolean
}

function SectionWrapperZeitung({
    id,
    content,
    isImageFirst = false,
}: ZeitungenWrapperType) {
    const [popupOpen, setPopupOpen] = useState(false)
    const [selectedArticle, setSelectedArticle] = useState<Article | null>(null)

    const openPopup = (article: Article) => {
        setSelectedArticle(article)
        setPopupOpen(true)
    }
    useEffect(() => {
        if (splideRef.current) {
            splideRef.current.splide.refresh()
        }
    }, [content])

    const closePopup = () => setPopupOpen(false)

    const articles = content?.articles || []
    const splideRef = useRef<any>(null)
    const latestArticle = articles.length > 0 ? articles[0] : null
    // Exclude the latest article from the slider
    const sliderArticles =
        articles.length > 1 ? articles.slice(1).reverse() : []

    const formatDate = (dateString: string) => {
        const date = new Date(dateString)
        return `${date.getDate().toString().padStart(2, '0')}.${(
            date.getMonth() + 1
        )
            .toString()
            .padStart(2, '0')}.${date.getFullYear()}`
    }

    const handlePrev = () => {
        if (splideRef.current) {
            splideRef?.current?.splide.go('-1') // Go to the previous slide
        }
    }

    const handleNext = () => {
        if (splideRef?.current) {
            splideRef?.current?.splide.go('+1') // Go to the next slide
        }
    }

    const [ref1, isVisible1] = useOnScreen({ threshold: 0.5 })
    const [ref2, isVisible2] = useOnScreen({ threshold: 0.5 })

    return (
        <div id={id} className="max-w-page mx-auto text-base p-4">
            <SectionHeading heading={content?.heading} />
            {latestArticle && (
                <motion.div
                    ref={ref1}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isVisible1 && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className={`hidden md:flex border border-orange_dark shadow-xl ${
                        isImageFirst ? 'flex-row-reverse' : ''
                    }`}>
                    <div className="w-3/5 px-24 py-20 space-y-4">
                        <HashLink onClick={() => openPopup(latestArticle)}>
                            <h1 className="text-orange_dark font-bold text-3xl">
                                {latestArticle.heading}
                            </h1>
                        </HashLink>
                        <p className="line-clamp-3 font-medium">
                            {latestArticle.content}
                        </p>
                        <div className="flex justify-between items-center">
                            <p className="text-sm text-orange_dark">
                                {formatDate(
                                    latestArticle?.image?.data?.attributes
                                        ?.createdAt
                                )}
                            </p>
                            <HashLink
                                onClick={() => openPopup(latestArticle)}
                                className="hover:underline">
                                <p>Mehr Erfahren...</p>
                            </HashLink>
                        </div>
                    </div>
                    <div>
                        <img
                            className="object-cover h-[22rem] w-[30rem]"
                            src={
                                API.img +
                                latestArticle.image.data.attributes.url
                            }
                            alt={
                                latestArticle.image.data.attributes.name ||
                                'Zeitung Image'
                            }
                        />
                    </div>
                </motion.div>
            )}
            {latestArticle && (
                <div className="md:hidden p-4">
                    <div>
                        <img
                            className="object-cover w-full max-h-56 mx-auto"
                            src={
                                API.img +
                                latestArticle.image.data.attributes.url
                            }
                            alt={
                                latestArticle.image.data.attributes.name ||
                                'Zeitung Image'
                            }
                        />
                    </div>
                    <div className="p-4 space-y-6">
                        <HashLink onClick={() => openPopup(latestArticle)}>
                            <h1 className="text-orange_dark font-bold text-2xl">
                                {latestArticle.heading}
                            </h1>
                        </HashLink>
                        <p className="line-clamp-3 font-medium">
                            {latestArticle.content}
                        </p>
                        <div className="flex justify-between items-center">
                            <p className="text-sm text-orange_dark">
                                {formatDate(
                                    latestArticle?.image?.data?.attributes
                                        ?.createdAt
                                )}
                            </p>
                            <HashLink
                                onClick={() => openPopup(latestArticle)}
                                className="hover:underline">
                                <p>Mehr Erfahren...</p>
                            </HashLink>
                        </div>
                    </div>
                </div>
            )}
            {
                <motion.div
                    ref={ref2}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isVisible2 && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="md:flex">
                    {sliderArticles.length > 2 && (
                        <button
                            className="hidden md:block text-3xl text-content"
                            aria-label="Previous"
                            onClick={handlePrev}>
                            <MdArrowBackIos />
                        </button>
                    )}
                    <Splide
                        options={{
                            perPage: 3,
                            pagination: true,
                            gap: '1rem',
                            onMounted: () => {
                                if (splideRef.current) {
                                    splideRef.current.splide.refresh()
                                }
                            },
                            arrows: false,
                            breakpoints: {
                                768: {
                                    perPage: 1, // Auf kleineren Bildschirmen nur eine Slide anzeigen
                                },
                            },
                        }}
                        ref={splideRef}
                        aria-label="Article Slider">
                        {sliderArticles.map((article) => (
                            <SplideSlide key={article.id}>
                                <div className="p-4 max-w-full">
                                    {article.image && (
                                        <img
                                            className="object-cover w-full max-h-56 mx-auto"
                                            src={
                                                API.img +
                                                article.image.data.attributes
                                                    .url
                                            }
                                            alt={
                                                article.image.data.attributes
                                                    .name ||
                                                'Zeitung Article Image'
                                            }
                                        />
                                    )}
                                    <div className="space-y-4">
                                        <HashLink
                                            onClick={() => openPopup(article)}>
                                            <h3 className="text-orange_dark font-bold text-xl md:text-2xl">
                                                {article.heading}
                                            </h3>
                                        </HashLink>
                                        <p className="line-clamp-6 text-sm md:text-base">
                                            {article.content}
                                        </p>
                                        <div className="flex justify-between items-center">
                                            <p className="text-xs md:text-sm text-orange_dark">
                                                {formatDate(
                                                    article?.image?.data
                                                        ?.attributes?.createdAt
                                                )}
                                            </p>
                                            <HashLink
                                                onClick={() =>
                                                    openPopup(article)
                                                }
                                                className="hover:underline text-xs md:text-sm">
                                                Mehr Erfahren...
                                            </HashLink>
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                        ))}
                    </Splide>

                    {sliderArticles.length > 2 && (
                        <button
                            className="hidden md:block text-3xl text-content"
                            aria-label="Next"
                            onClick={handleNext}>
                            <MdArrowForwardIos />
                        </button>
                    )}
                </motion.div>
            }
            {popupOpen && selectedArticle && (
                <PopupArticle article={selectedArticle} onClose={closePopup} />
            )}
        </div>
    )
}

export default SectionWrapperZeitung
