import React from 'react'
import SectionHeading from './SectionHeading'

type sectionData = {
    content:{
        heading:string,
        subheading:string,
    } | undefined
}

function SectionMeldungen({content}: sectionData) {
  return (
    <div className='mx-auto max-w-page px-8 pb-8 '>
        <SectionHeading heading={content?.heading} />
        <div className='text-center pb-8'>
        {content?.subheading}
      </div>
    </div>
  )
}

export default SectionMeldungen