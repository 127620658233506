import './css/index.css'

export default function ErrorPage() {
    return (
        <div className="flex w-full h-fit bg-white justify-center items-center py-40">
            <section className="page_404 w-[40rem]">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 ">
                            <div className="col-sm-10 col-sm-offset-1  text-center">
                                <div className="four_zero_four_bg">
                                    <h1 className="text-center ">404</h1>
                                </div>

                                <div className="contant_box_404">
                                    <h3 className="h2">
                                        Seite nicht gefunden!
                                    </h3>

                                    <p>
                                        Die Seite, die Sie aufgerufen haben,
                                        existiert nicht.
                                    </p>

                                    <a href="/" className="btn btn-primary">
                                        Zur Homepage
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
