import Hero from '../../components/custom/Hero'
import SectionHomeSlider from '../../components/custom/SectionHomeSlider'
import SectionWelcome from '../../components/custom/SectionWelcome'
import SectionContact from '../../components/custom/SectionContact'
import SectionPromotions from '../../components/custom/SectionPromotions'

import { useHomepageData, useHomepageImages } from '../../hooks/useHomepageData'
import ErrorPage from '../404/ErrorPage'
import Loading from '../../components/common/Loading'

export default function Homepage() {
    const {
        data: homepageData,
        isLoading: isLoadingHomepageData,
        error: errorHomepageData,
    } = useHomepageData()
    const {
        data: homepageImages,
        isLoading: isLoadingHomepageImages,
        error: errorHomepageImages,
    } = useHomepageImages()

    return (
        <>
            {(isLoadingHomepageData || isLoadingHomepageImages) && <Loading />}
            {(errorHomepageData || errorHomepageImages) && <ErrorPage />}
            {!isLoadingHomepageData &&
                !isLoadingHomepageData &&
                !errorHomepageData &&
                !errorHomepageImages &&
                homepageData &&
                homepageImages && (
                    <>
                        <Hero
                            pageTitle={homepageData?.hero.headline}
                            subheading={homepageData?.hero.subtitle}
                            heroImg={
                                homepageData?.hero.hero_img.data.attributes.url
                            }
                        />
                        <SectionWelcome content={homepageData?.Welcome} />
                        <SectionHomeSlider
                            images={homepageImages?.Carousel.data}
                        />
                        <SectionContact />
                        <SectionPromotions
                            images={homepageImages?.Promotions.data}
                        />
                    </>
                )}
        </>
    )
}
