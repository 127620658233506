import React from 'react'
import { SchuleChildType } from '../../types/types'
import SectionHeading from './SectionHeading'
import { API } from '../../data/constants'
import { useOnScreen } from '../common/smoothInEffektHook'
import { motion } from 'framer-motion'

function SectionSchulleitung(content: SchuleChildType) {
    const [ref, isVisible] = useOnScreen({ threshold: 0.5 })

    return (
        <div id="schulleitung" className="max-w-page mx-auto px-8 mb-8">
            <SectionHeading
                heading={content?.content?.schulleitung.heading ?? ''}
            />
            <div className="flex gap-12 md:gap-16 flex-col md:flex-row justify-center ">
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: -100 }}
                    animate={isVisible && { opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className="p-4 bg-white shadow-xl rounded-lg mx-auto md:mx-0 justify-self-end">
                    <img
                        className="max-h-[400px]"
                        src={
                            API.img +
                            content?.content?.schulleitung?.bild1?.data
                                ?.attributes?.url
                        }
                        alt=""
                    />
                    <div className="text-center">
                        <p className="pt-4 font-semibold text-lg pb-0 mb-1">
                            {content?.content?.schulleitung?.name1}
                        </p>
                        <p className="text-lg">
                            {content?.content?.schulleitung?.posi1}
                        </p>
                        <a
                            href={`mailto:${content?.content?.schulleitung?.mail1}`}>
                            {content?.content?.schulleitung?.mail1}
                        </a>
                    </div>
                </motion.div>
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0, x: 100 }}
                    animate={isVisible && { opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className="p-4 bg-white shadow-xl rounded-lg mx-auto md:mx-0 justify-self-start">
                    <img
                        className="max-h-[400px]"
                        src={
                            API.img +
                            content?.content?.schulleitung?.bild2?.data
                                ?.attributes?.url
                        }
                        alt=""
                    />
                    <div className="text-center">
                        <p className="pt-4 font-semibold text-lg pb-0 mb-1">
                            {content?.content?.schulleitung?.name2}
                        </p>
                        <p className="text-lg ">
                            {content?.content?.schulleitung?.posi2}
                        </p>
                        <a
                            href={`mailto:${content?.content?.schulleitung?.mail2}`}>
                            {content?.content?.schulleitung?.mail2}
                        </a>
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default SectionSchulleitung
