import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '../data/constants';
import { SchulanmeldungType} from '../types/types';

export const useSchulanmeldungData = () => {
  return useQuery<SchulanmeldungType, Error>({
    queryKey: ['schulanmeldungData'],
    queryFn: () => axios.get(`${API.general}/schulanmeldung?populate=anmeldung.Datei&populate=meldungen&populate=hero.hero_img`)
                        .then(res => res.data.data.attributes)
  });
};

