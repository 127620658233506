import { motion } from 'framer-motion'
import { useOnScreen } from '../common/smoothInEffektHook'

type SectionHeadingType = {
    heading?: string
    subheading?: string
}

function SectionHeadingWhite({ heading, subheading }: SectionHeadingType) {
    const [ref, isVisible] = useOnScreen({ threshold: 1 })
    const [ref2, isVisible2] = useOnScreen({ threshold: 1 })

    return (
        <div className="max-w-page mx-auto py-8 pb-4 sm:pb-8 flex flex-col justify-center text-center space-y-6">
            <div className="w-fit mx-auto space-y-6">
                <motion.div
                    ref={ref}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isVisible && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="font-permanentMarker text-3xl sm:text-5xl text-primary"
                    id="heading">
                    {heading || ''}
                </motion.div>
                <div className="flex justify-center flex-col px-16 w-full space-y-2">
                    <div className="flex">
                        <div className="w-2/5"></div>
                        <div className="h-1 w-full bg-orange_dark rounded-full"></div>
                    </div>
                    <div className="flex">
                        <div className="h-1 w-full bg-primary rounded-full"></div>
                        <div className="w-2/5"></div>
                    </div>
                </div>
            </div>
            <motion.div
                ref={ref2}
                initial={{ opacity: 0, y: 50 }}
                animate={isVisible2 && { opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="text-content">
                {subheading || ''}
            </motion.div>
        </div>
    )
}

export default SectionHeadingWhite
