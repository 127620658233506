import { type BlocksContent, BlocksRenderer } from '@strapi/blocks-react-renderer'
import Loading from '../../components/common/Loading'
import Hero from '../../components/custom/Hero'
import SectionContact from '../../components/custom/SectionContact'
import SectionWrapperZeitung from '../../components/custom/SectionWrapperZeitung'
import { useAktuellesData } from '../../hooks/useAktuellesData'
import ErrorPage from '../404/ErrorPage'

function Aktuelles() {
    const {
        data: aktuellesData,
        isLoading: isLoadingAktuellesData,
        error: errorAktuellesData,
    } = useAktuellesData()


    const content: BlocksContent = aktuellesData?.subheading 
    return (
        <>
            {isLoadingAktuellesData && <Loading />}
            {errorAktuellesData && <ErrorPage />}
            {!isLoadingAktuellesData && !errorAktuellesData && aktuellesData && (
                <>
                    <Hero
                        pageTitle={aktuellesData?.hero.headline}
                        heroImg={
                            aktuellesData?.hero.hero_img.data.attributes.url
                        }
                    />
                    <div className="max-w-page px-8 py-16 mx-auto text-center text-lg">
                        <BlocksRenderer content={content ?? []} />
                    </div>
                    <SectionWrapperZeitung
                        id="eltern"
                        content={aktuellesData?.elternzeitung}
                        isImageFirst={false}
                    />
                    <SectionWrapperZeitung
                        id="schueler"
                        content={aktuellesData?.schuelerzeitung}
                        isImageFirst={true}
                    />
                    <SectionContact />
                </>
            )}
        </>
    )
}

export default Aktuelles
