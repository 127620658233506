import { SchuleChildType } from '../../types/types'
import SectionHeading from './SectionHeading'
import { ReactComponent as Phone } from '../../assets/ic_baseline-phone.svg'
import { ReactComponent as Mail } from '../../assets/material-symbols_mail.svg'
import { useOnScreen } from '../common/smoothInEffektHook'
import { motion } from 'framer-motion'

function Sekretariat({ content }: SchuleChildType) {
    const [ref2, isVisible2] = useOnScreen({ threshold: 1 })
    const [ref3, isVisible3] = useOnScreen({ threshold: 1 })
    const [ref4, isVisible4] = useOnScreen({ threshold: 1 })
    const [ref5, isVisible5] = useOnScreen({ threshold: 1 })
    return (
        <div
            className="max-w-page mx-auto px-8 pb-16 flex flex-col justify-center text-center space-y-6"
            id="sekretariat">
            <SectionHeading
                heading={content?.sekretariat?.heading_sekretariat ?? ''}
                subheading={content?.sekretariat?.subheading_sekretariat}
            />
            <div className="flex justify-center flex-col sm:flex-row pb-8">
                <div className="pb-8 sm:pb-0 sm:pr-8 border-b-2 sm:border-b-0  sm:border-r-2">
                    <motion.p
                        ref={ref2}
                        initial={{ opacity: 0, y: 50 }}
                        animate={isVisible2 && { opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        className="pb-4">
                        {content?.sekretariat?.tage1_sekretariat}
                        <br />
                        {content?.sekretariat?.zeiten1_sekretariat}
                    </motion.p>
                    <motion.p
                        ref={ref3}
                        initial={{ opacity: 0, y: 50 }}
                        animate={isVisible3 && { opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}>
                        {content?.sekretariat?.tage2_sekretariat}
                        <br />
                        {content?.sekretariat?.zeiten2_sekretariat}
                    </motion.p>
                </div>
                <motion.div
                    ref={ref4}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isVisible4 && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="pt-8 sm:pt-0 sm:pl-8 flex flex-col justify-center items-center sm:items-start gap-4">
                    <a
                        className="flex gap-4 items-center"
                        href={`tel:${content?.sekretariat?.tel_sekretariat}`}>
                        <Phone className="w-10 h-10" />
                        <span>{content?.sekretariat?.tel_sekretariat}</span>
                    </a>
                    <a
                        className="flex gap-4 items-center"
                        href={`mailto:${content?.sekretariat?.mail_sekretariat}`}>
                        <Mail className="w-10 h-10" />
                        <span>{content?.sekretariat?.mail_sekretariat}</span>
                    </a>
                </motion.div>
            </div>
            <motion.p
                ref={ref5}
                initial={{ opacity: 0, y: 50 }}
                animate={isVisible5 && { opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="text-center">
                {content?.sekretariat?.text_sekretariat}
            </motion.p>
        </div>
    )
}

export default Sekretariat
