import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '../data/constants';
import { ImageType } from '../types/types';

type NavigationType = {
    schulanmeldung: boolean;
    logo: {
        data: ImageType;
    };
}


export const useNavigationData = () => {
    return useQuery<NavigationType, Error>({
        queryKey: ['navigationData'],
        queryFn: () => axios.get(`${API.general}/navigation?populate=*`)
            .then(res => res.data.data.attributes)
    });
};
