import { useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import { motion } from 'framer-motion'
import { Navigate, Route, Routes } from 'react-router-dom'
import Header from './components/common/Header'
import Footer from './components/common/Footer'
import BaseLayout from './components/layout/BaseLayout'
import Homepage from './pages/Homepage/Homepage'
import Impressum from './pages/Impressum/Impressum'
import Datenschutz from './pages/Datenschutz/Datenschutz'
import ErrorPage from './pages/404/ErrorPage'
import Aktuelles from './pages/Aktuelles/Aktuelles'
import UnsereSchule from './pages/Unsere Schule/UnsereSchule'
import Schulanmeldung from './pages/Schulanmeldung/Schulanmeldung'
import Kontakt from './pages/Kontakt/Kontakt'
import Team from './pages/Team/Team'
import Transparenzregister from './pages/Transparenzregister/Transparenzregister'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

const GA_TRACKING_ID = 'X-2FJS7NAF7A' // hier ANPASSEN
const analyticsCookieName = '83b3d76f-94ef-5639-ae33-d172cfa45ad1' // hier ANPASSEN

function App() {
    const [visible, setVisible] = useState(false)
    const [presence, setPresence] = useState(false)

    useEffect(() => {
        if (localStorage.getItem(analyticsCookieName) === null) {
            console.log('call')
            setPresence(true)
            setVisible(true)
        } else if (localStorage.getItem(analyticsCookieName)) {
            enableAnalytics()
        }
    }, [])

    const handleAcceptAll = () => {
        localStorage.setItem(analyticsCookieName, 'true') // das anpassen!
        setVisible(!visible)
        setTimeout(() => {
            setPresence(!presence)
        }, 550)
        enableAnalytics()
    }

    const handleAcceptNecessary = () => {
        setVisible(!visible)
        setTimeout(() => {
            setPresence(!presence)
        }, 550)
    }

    // enables Google Analytics
    // hier ANPASSEN
    const enableAnalytics = () => {
        ReactGA.initialize(GA_TRACKING_ID)
    }

    return (
        <QueryClientProvider client={queryClient}>
            <main className="relative overflow-y-scroll overflow-x-hidden h-screen bg-primary text-content text-lg">
                {presence && (
                    <motion.div
                        initial={{
                            y: 0,
                            opacity: 1,
                        }}
                        animate={{
                            y: !visible ? '10%' : 0,
                            opacity: !visible ? 0 : 1,
                        }}
                        transition={{
                            ease: 'linear',
                            duration: 0.3,
                        }}
                        className={`bg-primary flex fixed bottom-0 md:bottom-16 border-orange_dark border-2 right-0 md:right-16 w-full h-fit md:w-[36rem] md:min-h-[20rem] rounded-xl flex-col justify-center p-8 z-50 text-content shadow-lg`}>
                        <h4 className="text-lg sm:text-xl md:text-2xl font-bold underline pb-2">
                            Diese Website nutzt Cookies & Google Analytics!
                        </h4>
                        <p className="text-xs">
                            Wir verwenden Cookies und andere Technologien auf
                            unserer Website. Einige von ihnen sind essenziell,
                            während andere uns helfen, diese Website und Ihre
                            Erfahrung zu verbessern. Personenbezogene Daten
                            können verarbeitet werden (z. B. IP-Adressen), z. B.
                            für personalisierte Anzeigen und Inhalte oder
                            Anzeigen- und Inhaltsmessung. Weitere Informationen
                            über die Verwendung Ihrer Daten finden Sie in
                            unserer Datenschutzerklärung. Sie können Ihre
                            Auswahl jederzeit unter Einstellungen widerrufen
                            oder anpassen.
                        </p>
                        <div className="flex flex-col-reverse justify-center items-center sm:flex-row w-full sm:justify-between mt-6">
                            <button
                                onClick={handleAcceptNecessary}
                                className="text-black/20 text-sm hover:text-black/10">
                                Nur notwendige zulassen
                            </button>
                            <button
                                onClick={handleAcceptAll}
                                className="btn btn-success btn-outline w-48 mb-4 sm:mb-0">
                                Bestätigen
                            </button>
                        </div>
                    </motion.div>
                )}
                {
                    <div className={'flex flex-col w-full min-h-screen'}>
                        <Header />
                        <Routes>
                            <Route
                                path="/"
                                element={
                                    <BaseLayout
                                        title="Zwickau"
                                        children={<Homepage />}
                                    />
                                }
                            />
                            <Route
                                path="/aktuelles"
                                element={
                                    <BaseLayout
                                        title="Aktuelles"
                                        children={<Aktuelles />}
                                    />
                                }
                            />
                            <Route
                                path="/schulanmeldung"
                                element={
                                    <BaseLayout
                                        title="Schulanmeldung"
                                        children={<Schulanmeldung />}
                                    />
                                }
                            />
                            <Route
                                path="/team"
                                element={
                                    <BaseLayout
                                        title="Team"
                                        children={<Team />}
                                    />
                                }
                            />
                            <Route
                                path="/transparenzregister"
                                element={
                                    <BaseLayout
                                        title="Transparenzregister"
                                        children={<Transparenzregister />}
                                    />
                                }
                            />
                            <Route
                                path="/unsere-schule"
                                element={
                                    <BaseLayout
                                        title="Unsere Schule"
                                        children={<UnsereSchule />}
                                    />
                                }
                            />
                            <Route
                                path="/kontakt"
                                element={
                                    <BaseLayout
                                        title="Kontakt"
                                        children={<Kontakt />}
                                    />
                                }
                            />
                            <Route
                                path="/datenschutz"
                                element={
                                    <BaseLayout
                                        title="Datenschutz"
                                        children={<Datenschutz />}
                                    />
                                }
                            />
                            <Route
                                path="/impressum"
                                element={
                                    <BaseLayout
                                        title="Impressum"
                                        children={<Impressum />}
                                    />
                                }
                            />
                            <Route
                                path="/404"
                                element={
                                    <BaseLayout
                                        title="Page Not Found"
                                        children={<ErrorPage />}
                                    />
                                }
                            />
                            <Route path="*" element={<Navigate to="/404" />} />
                        </Routes>
                        {/* <ScrollToHashElement /> */}
                        <Footer />
                    </div>
                }
            </main>
        </QueryClientProvider>
    )
}

export default App
