import SectionHeading from './SectionHeading'
import { ReactComponent as Fussball } from '../../assets/fussball.svg'

import { WelcomeType } from '../../types/types'
import { useOnScreen } from '../common/smoothInEffektHook'
import { motion } from 'framer-motion'
//DATA

type WelcomeChildType = {
    content?: WelcomeType
}

function SectionWelcome({ content }: WelcomeChildType) {
    const [ref1, isVisible1] = useOnScreen({ threshold: 0.5 })
    const [ref2, isVisible2] = useOnScreen({ threshold: 0.5 })
    const [ref3, isVisible3] = useOnScreen({ threshold: 0.5 })

    return (
        <div className="max-w-page mx-auto px-8 py-16 mb-16">
            <SectionHeading
                heading={content?.heading}
                subheadingAsText={content?.subheading}
            />
            <div className="py-6 flex flex-row justify-around items-center text-center">
                <motion.div
                    ref={ref1}
                    initial={{ opacity: 0, x: -100 }}
                    animate={isVisible1 && { opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className="flex flex-col">
                    <div className="text-7xl text-orange_dark font-permanentMarker">
                        {content?.number_teacher}
                    </div>
                    <div className="font-semibold text-2xl">
                        {content?.fieldname_teacher}
                    </div>
                </motion.div>
                <motion.div
                    ref={ref2}
                    initial={{ opacity: 0 }}
                    animate={isVisible2 && { opacity: 1 }}
                    transition={{ duration: 1 }}
                    className="hidden sm:block">
                    {' '}
                    <Fussball />
                </motion.div>
                <motion.div
                    ref={ref3}
                    initial={{ opacity: 0, x: 100 }}
                    animate={isVisible3 && { opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className="flex flex-col">
                    <div className="text-7xl text-blue_dark font-permanentMarker">
                        {content?.number_students}
                    </div>
                    <div className="font-semibold text-2xl">
                        {content?.fieldname_students}
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default SectionWelcome
