import { motion } from 'framer-motion'
import { useOnScreen } from '../common/smoothInEffektHook'

type planData = {
    content?: {
        tag: string
        tabelle: {
            id: number
            zeiten: string
            angebot: string
            lehrer: string
        }[]
        aktiv: boolean
    }
}

function SectionGTAPlan({ content }: planData) {
    const [ref1, isVisible1] = useOnScreen({ threshold: 0.5 })
    const [ref2, isVisible2] = useOnScreen({ threshold: 0.5 })
    
    if (!content?.aktiv) {
        // Wenn aktiv auf false steht, die Komponente ausblenden
        return null
    }

    return (
        <div className="max-w-page mx-auto px-8 mb-16">
            <motion.div
                ref={ref1}
                initial={{ opacity: 0, y: 50 }}
                animate={isVisible1 && { opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="font-permanentMarker text-2xl sm:text-3xl pb-4">
                {content?.tag}
            </motion.div>
            <hr className="border border-blue_dark" />
            {content?.tabelle.map((ag) => (
                <motion.div
                    ref={ref2}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isVisible2 && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    key={ag.id}
                    className="grid grid-cols-7 sm:grid-cols-5 gap-2 sm:gap-4 py-4 border-b-2 border-blue_dark">
                    <div className="col-span-3 sm:col-span-1">{ag.zeiten}</div>
                    <div className=" col-span-4 sm:col-span-3">
                        {ag.angebot}
                        <p className="sm:hidden">{ag.lehrer}</p>
                    </div>
                    <div className="hidden sm:block">{ag.lehrer}</div>
                </motion.div>
            ))}
        </div>
    )
}

export default SectionGTAPlan
