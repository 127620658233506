import React from 'react'
import { Article } from '../../types/types';
import { API } from '../../data/constants';
import SectionHeading from './SectionHeading';
import { MdOutlineCancel } from 'react-icons/md';

type ArticlePopupType = {
    onClose: () => void;
    article: Article;
}

function PopupArticle({ onClose, article }: ArticlePopupType) {
    // Format date function
    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getFullYear()}`;
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto px-2">
            <div className="bg-primary sm:px-8 py-7 md:px-24 md:py-16 rounded-lg max-w-page w-full relative">
                <button className='absolute top-0 right-0 text-4xl text-blue_dark lg:active:animate-ping hover:duration-300 hover:rotate-90 mt-4 mr-4' onClick={onClose}>
                    <MdOutlineCancel className="block" />
                </button>
                <div className='mt-4 flex flex-col relative overflow-y-auto max-h-[80vh]'>

                    <SectionHeading heading={article?.heading} />
                    <p className="text-left text-orange_dark px-8">{formatDate(article?.image?.data?.attributes?.createdAt)}</p>
                    <div className="px-10">
                        {article.image && (
                            <img
                                className="object-cover md:float-left md:w-1/2 md:pr-8 mb-4"
                                src={API.img + article.image.data.attributes.url}
                                alt={article.image.data.attributes.name || 'Article Image'}
                            />
                        )}
                        <p className="text-justify">{article.content}</p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default PopupArticle