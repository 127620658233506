import React from 'react'

import SectionHeading from './SectionHeading'
import { PdfType } from '../../types/types'
import { API } from '../../data/constants'
import fileDownload from 'js-file-download'
import axios from 'axios'


type sectionData = {
  content?: {
    heading: string,
    subheading: string,
    Datei: PdfType,
  }
}



function SectionFormulars({ content }: sectionData) {

  const handleDownload = (url: string, filename: string) => {
    axios.get(url, { responseType: 'blob' })
      .then((res) => {
        fileDownload(res.data, filename);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  return (
    <div className='mx-auto max-w-page px-8 pb-8 sm:pb-16'>
      <SectionHeading heading={content?.heading}  />
      <div className='text-center pb-8'>
        {content?.subheading}
      </div>
      <div className='flex justify-center'>
        <button
          className="bg-orange_dark hover:bg-orange_light text-primary font-permanentMarker text-xl tracking-wider py-4 px-8 rounded-xl inline-flex items-center"
          onClick={() => {
            if (content?.Datei?.data?.attributes?.url && content?.Datei?.data?.attributes?.name) {
              handleDownload(API.img + content.Datei.data.attributes.url, content.Datei?.data?.attributes?.name);
            }
          }}
        >
          <svg className="fill-current w-10 h-10 sm:w-4 sm:h-4 mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" /></svg>
          <span>Anmeldung Schulanfänger</span>
        </button>
      </div>
    </div>
  )
}

export default SectionFormulars