import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '../data/constants';

import { UnsereSchuleType } from '../types/types';

export const useUnsereSchuleData = () => {
  return useQuery<UnsereSchuleType, Error>({
    queryKey: ['unsereSchuleData'],
    queryFn: () => axios.get(`${API.general}/unsere-schule-neu?populate[unterricht][populate]=*&populate[leitbild][populate][bewegte_schule][populate]=*&populate[leitbild][populate][eltern_foerderverein][populate]=*&populate[leitbild][populate][hort_kindergarten][populate]=*&populate[leitbild][populate][foerdern_fordern][populate]=*&populate[leitbild][populate][unterricht][populate]=*&populate[leitbild][populate][kooperation_international][populate]=*&populate[verpflichtungsangebote][populate][icons][populate]=*&populate[nachmittagsangebote][populate][icons][populate]=*&populate[montag][populate]=*&populate[dienstag][populate]=*&populate[mittwoch][populate]=*&populate[donnerstag][populate]=*&populate[freitag][populate]=*&populate[images][populate]=*&populate[hero][populate]=*`)
                        .then(res => res.data.data.attributes)
  });
};

