import React, { useEffect, useRef } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css' // Import default Splide CSS

import { ReactComponent as WellenOben } from '../../assets/wellen_oben.svg'
import { ReactComponent as WellenUnten } from '../../assets/wellen_unten.svg'
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'

import { ImageType } from '../../types/types'
import { API } from '../../data/constants'

type SliderImageType = {
    images?: ImageType[]
}

export default function SectionHomeSlider({ images }: SliderImageType) {
    const splideRef = useRef<any>(null)

    useEffect(() => {
        if (splideRef.current) {
            splideRef.current.splide.refresh()
        }
    }, [images])

    const handlePrev = () => {
        if (splideRef.current) {
            splideRef?.current?.splide.go('-1') // Go to the previous slide
        }
    }

    const handleNext = () => {
        if (splideRef?.current) {
            splideRef?.current?.splide.go('+1') // Go to the next slide
        }
    }

    return (
        <div className="relative bg-blue_dark">
            {' '}
            {/* Replace with Tailwind equivalent */}
            <WellenUnten />
            <div className="flex mx-auto justify-around px-4 md:px-0 ">
                <button
                    className="hidden md:block text-6xl text-primary p-8"
                    aria-label="Previous"
                    onClick={handlePrev}>
                    <MdArrowBackIos />
                </button>

                <div className="max-w-page bg-primary py-0 md:p-3  shadow-lg rounded-lg md:rounded-none md:h-[32rem]">
                    <Splide
                        options={{
                            type: 'slide',
                            perPage: 1,
                            pagination: true,
                            navigation: true,
                            arrows: false,
                            gap: '1rem',
                            onMounted: () => {
                                if (splideRef.current) {
                                    splideRef.current.splide.refresh()
                                }
                            },
                        }}
                        ref={splideRef}
                        aria-label="My Favorite Images">
                        {images?.map((image) => (
                            <SplideSlide
                                key={image.id}
                                className="md:h-[30.5rem]">
                                <div className="md:h-fit rounded-md">
                                    <img
                                        src={API.img + image.attributes.url}
                                        alt={image.attributes.name}
                                        className="w-full h-full"
                                    />
                                </div>
                            </SplideSlide>
                        ))}
                    </Splide>
                </div>

                <button
                    className="hidden md:block text-6xl text-primary p-8"
                    aria-label="Next"
                    onClick={handleNext}>
                    <MdArrowForwardIos />
                </button>
            </div>
            <WellenOben />
        </div>
    )
}
