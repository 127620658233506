import { API } from '../../data/constants'
import { ImageType } from '../../types/types'

type PromotionType = {
    images?: ImageType[]
}

function SectionPromotions({ images }: PromotionType) {
    return (
        <div className="w-full mx-auto py-16 flex flex-wrap justify-center items-center gap-8 lg:gap-16">
            {images?.map((image) => (
                <img
                    key={image.id}
                    src={API.img + image.attributes.url}
                    alt={image.attributes.name}
                    className="max-w-[24rem] h-auto max-h-64 " // Adjust width and height as needed
                />
            ))}
        </div>
    )
}

export default SectionPromotions
