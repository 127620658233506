import { HashLink } from 'react-router-hash-link'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { MdArrowDropDown } from 'react-icons/md'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { ReactComponent as DrawnCircle } from '../../assets/drawn_circle.svg'
import { ReactComponent as DrawnCircleLong } from '../../assets/drawn_circle_long.svg'
import { ReactComponent as DrawnCircleMid } from '../../assets/drawn_circle_mid.svg'
import { useNavigationData } from '../../hooks/useNavigation'
import { API } from '../../data/constants'
import { useAktuellesData } from '../../hooks/useAktuellesData'

export default function Header() {
    const {
        data: navigationData,
        isLoading: isLoadingNavigationData,
        error: errorNavigationData,
    } = useNavigationData()

    const {
        data: aktuellesData,
    } = useAktuellesData()

    const [nav, setNav] = useState(false)
    const [dropdownUnsereSchule, setDropdownUnsereSchule] = useState(false)
    const [dropdownTeam, setDropdownTeam] = useState(false)

    const [dropdownAktuelles, setDropdownAktuelles] = useState(false)
    const location = useLocation()

    const isActive = (path: string) => location.pathname === path

    const handleNav = () => {
        setNav(!nav)
    }

    const showDropdownUnsereSchule = () => {
        setDropdownUnsereSchule(true)
    }

    const hideDropdownUnsereSchule = () => {
        setDropdownUnsereSchule(false)
    }

    const showDropdownTeam = () => {
        setDropdownTeam(true)
    }

    const hideDropdownTeam = () => {
        setDropdownTeam(false)
    }

    const showDropdownAktuelles = () => {
        setDropdownAktuelles(true)
    }

    const hideDropdownAktuelles = () => {
        setDropdownAktuelles(false)
    }

    const navRef = useRef<HTMLUListElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                nav &&
                navRef.current &&
                !navRef.current.contains(event.target as Node)
            ) {
                setNav(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [nav])

    if (isLoadingNavigationData) {
        return <div></div>
    }

    if (errorNavigationData) {
        return <div>Error loading data</div>
    }

    return (
        <div className="sticky top-0 z-50 text-base">
            <nav className="bg-primary shadow-md  py-8 px-3 lg:px-6 md:p-8">
                <div className="max-w-page mx-auto">
                    <div className="flex justify-between items-center text-content text-base font-medium">
                        <HashLink to="/#_">
                            <img
                                src={
                                    API.img +
                                    navigationData?.logo?.data?.attributes?.url
                                }
                                alt=""
                                className="w-24 md:scale-[1.75]"
                            />
                        </HashLink>
                        <div className="hidden md:flex space-x-8 ">
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                }}>
                                <HashLink
                                    to="/#_"
                                    className="hover:bg-primary_hover px-4 py-2 rounded-full">
                                    Start
                                </HashLink>
                                {isActive('/#_') && (
                                    <DrawnCircle
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    />
                                )}
                            </div>
                            <div
                                className="relative"
                                onMouseEnter={showDropdownUnsereSchule}
                                onMouseLeave={hideDropdownUnsereSchule}>
                                <HashLink
                                    to="/unsere-schule#_"
                                    className="flex items-center">
                                    Unsere Schule
                                    <MdArrowDropDown
                                        className={`ml-1 scale-125 transform ${
                                            dropdownUnsereSchule
                                                ? 'rotate-180'
                                                : 'rotate-0'
                                        } transition-transform duration-200`}
                                    />
                                </HashLink>
                                {isActive('/unsere-schule#_') && (
                                    <DrawnCircleMid
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    />
                                )}
                                {dropdownUnsereSchule && (
                                    <div className="absolute bg-primary shadow-md">
                                        <HashLink
                                            to="/unsere-schule#leitbild"
                                            className="block  hover:bg-primary_hover px-4 py-2 ">
                                            Unser Leitbild
                                        </HashLink>
                                        <HashLink
                                            to="/unsere-schule#unterricht"
                                            className="block  hover:bg-primary_hover px-4 py-2 ">
                                            Unterrichtszeiten
                                        </HashLink>
                                        <HashLink
                                            to="/unsere-schule#gta"
                                            className="block  hover:bg-primary_hover px-4 py-2 ">
                                            GTA
                                        </HashLink>
                                    </div>
                                )}
                            </div>
                            <div
                                className="relative"
                                onMouseEnter={showDropdownAktuelles}
                                onMouseLeave={hideDropdownAktuelles}>
                                <HashLink
                                    to="/aktuelles#_"
                                    className="flex items-center">
                                    Aktuelles
                                    <MdArrowDropDown
                                        className={`ml-1 scale-125 transform ${
                                            dropdownAktuelles
                                                ? 'rotate-180'
                                                : 'rotate-0'
                                        } transition-transform duration-200`}
                                    />
                                </HashLink>
                                {isActive('/aktuelles#_') && (
                                    <DrawnCircle
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    />
                                )}
                                {dropdownAktuelles && (
                                    <div className="absolute bg-primary shadow-md">
                                        <HashLink
                                            to="/aktuelles#eltern"
                                            className="block hover:bg-primary_hover px-4 py-2">
                                            {aktuellesData?.elternzeitung
                                                ?.heading ?? 'Elternzeitung'}
                                        </HashLink>
                                        <HashLink
                                            to="/aktuelles#schueler"
                                            className="block hover:bg-primary_hover px-4 py-2">
                                            {aktuellesData?.schuelerzeitung
                                                ?.heading ?? 'Schülerzeitung'}
                                        </HashLink>
                                    </div>
                                )}
                            </div>
                            <div
                                className="relative"
                                onMouseEnter={showDropdownTeam}
                                onMouseLeave={hideDropdownTeam}>
                                <HashLink
                                    to="/team#_"
                                    className="flex items-center">
                                    Unser Team
                                    <MdArrowDropDown
                                        className={`ml-1 scale-125 transform ${
                                            dropdownTeam
                                                ? 'rotate-180'
                                                : 'rotate-0'
                                        } transition-transform duration-200`}
                                    />
                                </HashLink>
                                {isActive('/team#_') && (
                                    <DrawnCircleMid
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    />
                                )}
                                {dropdownTeam && (
                                    <div className="absolute bg-primary shadow-md">
                                        <HashLink
                                            to="/team#schulleitung"
                                            className="block hover:bg-primary_hover px-4 py-2">
                                            Schulleitung
                                        </HashLink>
                                        <HashLink
                                            to="/team#sekretariat"
                                            className="block hover:bg-primary_hover px-4 py-2">
                                            Sekretariat
                                        </HashLink>
                                        <HashLink
                                            to="/team#teachers"
                                            className="block hover:bg-primary_hover px-4 py-2">
                                            Das Team
                                        </HashLink>
                                        <HashLink
                                            to="/team#schulsoz"
                                            className="block hover:bg-primary_hover px-4 py-2">
                                            Schulsozialarbeit
                                        </HashLink>
                                    </div>
                                )}
                            </div>
                            {navigationData?.schulanmeldung && (
                                <div
                                    style={{
                                        position: 'relative',
                                        display: 'inline-block',
                                    }}>
                                    <HashLink
                                        to="/schulanmeldung#_"
                                        className="hover:bg-primary_hover px-4 py-2 rounded-full">
                                        Schulanmeldung
                                    </HashLink>
                                    {isActive('/schulanmeldungv') && (
                                        <DrawnCircleLong
                                            style={{
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                transform:
                                                    'translate(-50%, -50%)',
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                            <div
                                style={{
                                    position: 'relative',
                                    display: 'inline-block',
                                }}>
                                <HashLink
                                    to="/kontakt#_"
                                    className="hover:bg-primary_hover px-4 py-2 rounded-full">
                                    Kontakt
                                </HashLink>
                                {isActive('/kontakt#_') && (
                                    <DrawnCircle
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    />
                                )}
                            </div>
                        </div>

                        {/* Hamburger Icon */}
                        <div className="md:hidden flex text-section_heading ml-auto z-50">
                            <button onClick={handleNav}>
                                {nav ? (
                                    <AiOutlineClose size={35} />
                                ) : (
                                    <AiOutlineMenu size={35} />
                                )}
                            </button>
                        </div>

                        {/* Mobile Navigation */}
                        <ul
                            ref={navRef}
                            className={`fixed top-0 right-0 pt-20 w-[60%] h-full bg-primary transform shadow-2xl border-l-1 border-orange_light ${
                                nav ? 'translate-x-0' : 'translate-x-full'
                            } transition-transform duration-300 ease-in-out md:hidden`}>
                            <li className="p-4 border-b border-orange_light">
                                <HashLink
                                    to="/"
                                    onClick={handleNav}
                                    className="block font-semibold">
                                    Start
                                </HashLink>
                            </li>
                            <li className="p-4 border-b border-orange_light">
                                <HashLink
                                    to="/unsere-schule"
                                    onClick={handleNav}
                                    className="block font-semibold">
                                    Unsere Schule
                                </HashLink>
                            </li>
                            <li className="p-4 border-b border-orange_light">
                                <HashLink
                                    to="/aktuelles"
                                    onClick={handleNav}
                                    className="block font-semibold">
                                    Aktuelles
                                </HashLink>
                            </li>
                            <li className="p-4 border-b border-orange_light">
                                <HashLink
                                    to="/team"
                                    onClick={handleNav}
                                    className="block font-semibold">
                                    Unser Team
                                </HashLink>
                            </li>
                            {navigationData?.schulanmeldung && (
                                <li className="p-4 border-b border-orange_light">
                                    <HashLink
                                        to="/schulanmeldung"
                                        onClick={handleNav}
                                        className="block font-semibold">
                                        Schulanmeldung
                                    </HashLink>
                                </li>
                            )}
                            <li className="p-4 border-b border-orange_light">
                                <HashLink
                                    to="/kontakt"
                                    onClick={handleNav}
                                    className="block font-semibold">
                                    Kontakt
                                </HashLink>
                            </li>
                            <li className="p-4 mt-16">
                                <HashLink
                                    to="/impressum"
                                    onClick={handleNav}
                                    className="block font-semibold">
                                    Impressum
                                </HashLink>
                            </li>
                            <li className="p-4">
                                <HashLink
                                    to="/datenschutz"
                                    onClick={handleNav}
                                    className="block font-semibold">
                                    Datenschutz
                                </HashLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}
