import {
    BlocksRenderer,
    type BlocksContent,
} from '@strapi/blocks-react-renderer'
import { useRegisterData } from '../../hooks/useRegisterData'
import ErrorPage from '../404/ErrorPage'
import Loading from '../../components/common/Loading'
// import { useRegisterHeroData } from '../../hooks/useRegisterData';

function Transparenzregister() {
    const {
        data: registerData,
        isLoading: isLoadingRegisterData,
        error: errorRegisterData,
    } = useRegisterData()

    const content: BlocksContent = registerData?.content

    return (
        <>
            {isLoadingRegisterData && <Loading />}
            {errorRegisterData && <ErrorPage />}
            {!isLoadingRegisterData && !errorRegisterData && registerData && (
                <div className="max-w-3xl px-8 py-6 mx-auto mt-24">
                    <BlocksRenderer content={content ?? []} />
                </div>
            )}
        </>
    )
}

export default Transparenzregister
