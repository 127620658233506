import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '../data/constants';
import { ContactDataType } from '../types/types';




export const useKontaktData = () => {
    return useQuery<ContactDataType, Error>({
        queryKey: ['contactData'],
        queryFn: () => axios.get(`${API.general}/kontakt?populate[contact_data][populate]=*&populate[hero][populate]=*`)
            .then(res => res.data.data.attributes)
    });
};
