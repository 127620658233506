
// Components----------
import Hero from '../../components/custom/Hero'
import Sekretariat from '../../components/custom/Sekretariat'
import SectionSchulleitung from '../../components/custom/SectionSchulleitung'
import SectionTeachers from '../../components/custom/SectionTeachers'
import SectionSchulsoz from '../../components/custom/SectionSchulsoz'
import SectionContact from '../../components/custom/SectionContact'

//Strapi---------------
import { useTeamData } from '../../hooks/useTeamData'
import Loading from '../../components/common/Loading'
import ErrorPage from '../404/ErrorPage'

function Team() {
    const {
        data: teamData,
        isLoading: isLoadingTeamData,
        error: errorTeamData,
    } = useTeamData()

    return (
        <>
            {isLoadingTeamData && <Loading />}
            {errorTeamData && <ErrorPage />}
            {!isLoadingTeamData && !errorTeamData && teamData && (
                <div>
                    <Hero
                        pageTitle={teamData?.hero.headline}
                        heroImg={teamData?.hero.hero_img.data.attributes.url}
                    />
                    <SectionSchulleitung content={teamData} />
                    <Sekretariat content={teamData} />
                    <SectionTeachers content={teamData?.Teachers} />
                    <SectionSchulsoz content={teamData?.schulsozialarbeit} />
                    <SectionContact />
                </div>
            )}
        </>
    )
}

export default Team
