import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '../data/constants';
import { HomepageDataType, HomepageImageType } from '../types/types';

export const useHomepageData = () => {
  return useQuery<HomepageDataType, Error>({
    queryKey: ['homepageData'],
    queryFn: () => axios.get(`${API.general}/startseite?populate[contact][populate]=*&populate[hero][populate]=*&populate[Welcome][populate]=*`)
                        .then(res => res.data.data.attributes)
  });
};

export const useHomepageImages = () => {
  return useQuery<HomepageImageType, Error>({
    queryKey: ['homepageImages'],
    queryFn: () => axios.get(`${API.general}/startseite?populate[Images][populate]=*`)
                        .then(res => res.data.data.attributes.Images)
  });
};