import Loading from '../../components/common/Loading'
import Hero from '../../components/custom/Hero'
import SectionContactData from '../../components/custom/SectionContactData'
import SectionContactForm from '../../components/custom/SectionContactForm'
import { useKontaktData } from '../../hooks/useKontaktData'
import ErrorPage from '../404/ErrorPage'

function Kontakt() {
    const {
        data: contactData,
        isLoading: isLoadingContactData,
        error: errorContactData,
    } = useKontaktData()

    return (
        <>
            {isLoadingContactData && <Loading />}
            {errorContactData && <ErrorPage />}
            {!isLoadingContactData && !errorContactData && contactData && (
                <>
                    <Hero
                        pageTitle={contactData?.hero.headline}
                        heroImg={contactData?.hero.hero_img.data.attributes.url}
                    />
                    <SectionContactData content={contactData} />
                    <SectionContactForm
                        content={contactData?.contact_data?.cta_section}
                    />
                </>
            )}
        </>
    )
}

export default Kontakt
