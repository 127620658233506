import React from 'react'
import Hero from '../../components/custom/Hero'
import { useSchulanmeldungData } from '../../hooks/useSchulanmeldungData'
import SectionFormulars from '../../components/custom/SectionFormulars'
import SectionMeldungen from '../../components/custom/SectionMeldungen'
import SectionContact from '../../components/custom/SectionContact'
import { useNavigationData } from '../../hooks/useNavigation'
import { Navigate } from 'react-router-dom'
import ErrorPage from '../404/ErrorPage'
import Loading from '../../components/common/Loading'

function Schulanmeldung() {
    const {
        data: schulanmeldungData,
        isLoading: isLoadingSchulanmeldungData,
        error: errorSchulanmeldungData,
    } = useSchulanmeldungData()
    const {
        data: navigationData,
        isLoading: isLoadingNavigationData,
        error: errorNavigationData,
    } = useNavigationData()
    if (!navigationData?.schulanmeldung) {
        return <Navigate to="/" replace />
    }

    return (
        <>
            {(isLoadingNavigationData || isLoadingSchulanmeldungData) && (
                <Loading />
            )}
            {(errorNavigationData || errorSchulanmeldungData) && <ErrorPage />}
            {!isLoadingNavigationData &&
                !isLoadingSchulanmeldungData &&
                !errorNavigationData &&
                !errorSchulanmeldungData &&
                navigationData &&
                schulanmeldungData && (
                    <>
                        <Hero
                            pageTitle={schulanmeldungData?.hero.headline}
                            heroImg={
                                schulanmeldungData?.hero.hero_img.data
                                    .attributes.url
                            }
                        />
                        <SectionFormulars
                            content={schulanmeldungData?.anmeldung}
                        />
                        <SectionMeldungen
                            content={schulanmeldungData?.meldungen}
                        />
                        <SectionContact />
                    </>
                )}
        </>
    )
}

export default Schulanmeldung
