import SectionHeading from './SectionHeading'
import { useOnScreen } from '../common/smoothInEffektHook'
import { motion } from 'framer-motion'

type unterrichtProps = {
    unterricht:
        | {
              heading: string
              subheading: string
              feld1: string
              zeiten1: string
              feld2: string
              zeiten2: string
              feld3: string
              zeiten3: string
              feld4: string
              zeiten4: string
              feld5: string
              zeiten5: string
              pause1: string
              pausenlaenge1: string
              pause2: string
              pausenlaenge2: string
              pause3: string
              pausenlaenge3: string
          }
        | undefined
}

function SectionUnterricht({ unterricht }: unterrichtProps) {
    const [ref1, isVisible1] = useOnScreen({ threshold: 1 })
    const [ref2, isVisible2] = useOnScreen({ threshold: 1 })
    const [ref3, isVisible3] = useOnScreen({ threshold: 1 })
    const [ref4, isVisible4] = useOnScreen({ threshold: 1 })
    const [ref5, isVisible5] = useOnScreen({ threshold: 1 })
    const [ref6, isVisible6] = useOnScreen({ threshold: 1 })
    const [ref7, isVisible7] = useOnScreen({ threshold: 1 })

    return (
        <div id="unterricht" className="max-w-page mx-auto px-4 xl:px-0">
            <SectionHeading
                heading={unterricht?.heading ?? ''}
                subheading={unterricht?.subheading ?? ''}
            />
            <div className="flex flex-col gap-4">
                <motion.div
                    ref={ref1}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isVisible1 && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="grid grid-cols-5">
                    <div className="col-span-2 bg-orange_dark text-primary py-4 flex justify-center items-center font-semibold">
                        {unterricht?.feld1}
                    </div>
                    <div className="col-span-3 text-orange_dark border border-orange_dark py-4 flex justify-center items-center font-semibold">
                        {unterricht?.zeiten1}
                    </div>
                </motion.div>
                <motion.div
                    ref={ref2}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isVisible2 && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="text-center text-orange_dark">
                    <span className="text-xl">{unterricht?.pause1}</span>
                    <br />
                    {unterricht?.pausenlaenge1}
                </motion.div>
                <motion.div
                    ref={ref3}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isVisible3 && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="grid grid-cols-5">
                    <div className="col-span-2 bg-orange_dark text-primary py-4 flex justify-center items-center font-semibold">
                        {unterricht?.feld2}
                    </div>
                    <div className="col-span-3 text-orange_dark border border-orange_dark py-4 flex justify-center items-center font-semibold">
                        {unterricht?.zeiten2}
                    </div>
                </motion.div>
                <motion.div
                    ref={ref4}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isVisible4 && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="text-center text-orange_dark">
                    <span className="text-xl">{unterricht?.pause2}</span>
                    <br />
                    {unterricht?.pausenlaenge2}
                </motion.div>
                <motion.div
                    ref={ref5}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isVisible5 && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="grid grid-cols-5">
                    <div className="col-span-2 bg-orange_dark text-primary py-4 flex justify-center items-center font-semibold">
                        {unterricht?.feld3}
                    </div>
                    <div className="col-span-3 text-orange_dark border border-orange_dark py-4 flex justify-center items-center font-semibold">
                        {unterricht?.zeiten3}
                    </div>
                </motion.div>
                <motion.div
                    ref={ref6}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isVisible6 && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="text-center text-orange_dark">
                    <span className="text-xl">{unterricht?.pause3}</span>
                    <br />
                    {unterricht?.pausenlaenge3}
                </motion.div>
                <motion.div
                    ref={ref7}
                    initial={{ opacity: 0, y: 50 }}
                    animate={isVisible7 && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    className="grid grid-cols-5">
                    <div className="col-span-2 bg-orange_dark text-primary py-4 flex justify-center items-center font-semibold border-b-2 border-orange_light">
                        {unterricht?.feld4}
                    </div>
                    <div className="col-span-3 text-orange_dark border border-orange_dark py-4 flex justify-center items-center font-semibold">
                        {unterricht?.zeiten4}
                    </div>
                    <div className="col-span-2 bg-orange_dark text-primary py-4 flex justify-center items-center font-semibold ">
                        {unterricht?.feld5}
                    </div>
                    <div className="col-span-3 text-orange_dark border border-orange_dark border-t-0 py-4 flex justify-center items-center font-semibold">
                        {unterricht?.zeiten5}
                    </div>
                </motion.div>
            </div>
        </div>
    )
}

export default SectionUnterricht
