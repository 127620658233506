//aktuelle?populate[elternzeitung][populate][articles][populate]=image&populate[schuelerzeitung][populate][articles][populate]=image
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '../data/constants';


// type DatenschutzType = {
// content?: RichTextInput;
// } 

export const useDatenschutzData = () => {
    return useQuery<any, Error>({
        queryKey: ['datenschutzData'],
        queryFn: () => axios.get(`${API.general}/datenschutz?populate=content`)
            .then(res => res.data.data.attributes)
    });
};
