import React, { useState } from 'react'
import SectionHeading from './SectionHeading'
import { ContentSection, ImageType } from '../../types/types'
import { API } from '../../data/constants'
import PopupLeitbild from './PopupLeitbild'
import { useOnScreen } from '../common/smoothInEffektHook'
import { motion } from 'framer-motion'

type leitbildData = {
    content?: {
        heading: string
        bewegte_schule: {
            bild: {
                data: ImageType
            }
            heading: string
            text: string
        }
        eltern_foerderverein: {
            bild: {
                data: ImageType
            }
            heading: string
            text: string
        }
        hort_kindergarten: {
            bild: {
                data: ImageType
            }
            heading: string
            text: string
        }
        foerdern_fordern: {
            bild: {
                data: ImageType
            }
            heading: string
            text: string
        }
        unterricht: {
            bild: {
                data: ImageType
            }
            heading: string
            text: string
        }
        kooperation_international: {
            bild: {
                data: ImageType
            }
            heading: string
            text: string
        }
    }
}

type ContentKeys =
    | 'bewegte_schule'
    | 'eltern_foerderverein'
    | 'hort_kindergarten'
    | 'foerdern_fordern'
    | 'unterricht'
    | 'kooperation_international'

function SectionLeitbild({ content }: leitbildData) {
    const [popupOpen, setPopupOpen] = useState(false)
    const [popupContent, setPopupContent] = useState<ContentSection>()
    const closePopup = () => setPopupOpen(false)

    const openPopupWithSection = (sectionKey: ContentKeys) => {
        if (content) {
            const sectionContent = content[sectionKey] as ContentSection
            if (sectionContent) {
                setPopupContent(sectionContent) // Assuming you store this in state
                setPopupOpen(true)
            }
        }
    }

    const [ref1, isVisible1] = useOnScreen({ threshold: 0.8 })
    const [ref2, isVisible2] = useOnScreen({ threshold: 0.5 })
    const [ref3, isVisible3] = useOnScreen({ threshold: 0.5 })
    const [ref4, isVisible4] = useOnScreen({ threshold: 0.5 })
    const [ref5, isVisible5] = useOnScreen({ threshold: 0.5 })
    const [ref6, isVisible6] = useOnScreen({ threshold: 0.5 })

    return (
        <div id="leitbild">
            <SectionHeading heading={content?.heading} />
            <div className="grid grid-cols-1 sm:grid-cols-3  mx-auto gap-4 max-w-page px-4 xl:px-0 ">
                <motion.div
                    ref={ref1}
                    initial={{ opacity: 0, y: -100 }}
                    animate={isVisible1 && { opacity: 1, y: 0 }}
                    transition={{ duration: 1 }}
                    onClick={() => openPopupWithSection('bewegte_schule')}
                    className="hover:cursor-pointer sm:col-span-2 row-span-2 relative flex justify-center items-center p-16 sm:p-32 border-8 border-orange_dark rounded-2xl">
                    <img
                        className="w-full h-full absolute top-0 left-0 object-cover brightness-75 rounded"
                        src={
                            API.img +
                            content?.bewegte_schule?.bild?.data?.attributes?.url
                        }
                        alt=""
                    />
                    <div className="font-permanentMarker text-2xl sm:text-3xl md:text-4xl z-10 text-primary text-center">
                        {content?.bewegte_schule?.heading}
                    </div>
                </motion.div>
                <motion.div
                    ref={ref2}
                    initial={{ opacity: 0, x: 100, y: -50 }}
                    animate={isVisible2 && { opacity: 1, x: 0, y: 0 }}
                    transition={{ duration: 1 }}
                    onClick={() => openPopupWithSection('eltern_foerderverein')}
                    className="hover:cursor-pointer p-4 relative flex justify-center items-center  border-8 border-blue_dark rounded-2xl">
                    <img
                        className="w-full h-full absolute top-0 left-0 object-cover brightness-75 rounded"
                        src={
                            API.img +
                            content?.eltern_foerderverein?.bild?.data
                                ?.attributes?.url
                        }
                        alt=""
                    />
                    <div className="font-permanentMarker text-2xl sm:text-3xl md:text-4xl z-10 text-primary text-center">
                        {content?.eltern_foerderverein?.heading}
                    </div>
                </motion.div>
                <motion.div
                    ref={ref3}
                    initial={{ opacity: 0, x: 100 }}
                    animate={isVisible3 && { opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    onClick={() => openPopupWithSection('hort_kindergarten')}
                    className="hover:cursor-pointer p-4 relative flex justify-center items-center  border-8 border-blue_dark rounded-2xl">
                    <img
                        className="w-full h-full absolute top-0 left-0 object-cover brightness-75 rounded"
                        src={
                            API.img +
                            content?.hort_kindergarten?.bild?.data?.attributes
                                ?.url
                        }
                        alt=""
                    />
                    <div className="font-permanentMarker text-2xl sm:text-3xl md:text-4xl z-10 text-primary text-center">
                        {content?.hort_kindergarten?.heading}
                    </div>
                </motion.div>
                <motion.div
                    ref={ref4}
                    initial={{ opacity: 0, x: -50 }}
                    animate={isVisible4 && { opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    onClick={() => openPopupWithSection('foerdern_fordern')}
                    className="hover:cursor-pointer p-4 relative flex justify-center items-center  border-8 border-blue_dark rounded-2xl">
                    <img
                        className="w-full h-full absolute top-0 left-0 object-cover brightness-75 rounded"
                        src={
                            API.img +
                            content?.foerdern_fordern?.bild?.data?.attributes
                                ?.url
                        }
                        alt=""
                    />
                    <div className="font-permanentMarker text-2xl sm:text-3xl md:text-4xl z-10 text-primary text-center">
                        {content?.foerdern_fordern?.heading}
                    </div>
                </motion.div>
                <motion.div
                    ref={ref5}
                    initial={{ opacity: 0, y: 50, x: 100 }}
                    animate={isVisible5 && { opacity: 1, y: 0, x: 0 }}
                    transition={{ duration: 1 }}
                    onClick={() =>
                        openPopupWithSection('kooperation_international')
                    }
                    className="hover:cursor-pointer order-6 sm:order-5 sm:col-span-2 row-span-2 relative flex justify-center items-center p-16 sm:p-32 border-8 border-orange_dark rounded-2xl">
                    <img
                        className="w-full h-full absolute top-0 left-0 object-cover brightness-75 rounded"
                        src={
                            API.img +
                            content?.kooperation_international?.bild?.data
                                ?.attributes?.url
                        }
                        alt=""
                    />
                    <div className="font-permanentMarker text-2xl sm:text-3xl md:text-4xl z-10 text-primary text-center">
                        {content?.kooperation_international?.heading}
                    </div>
                </motion.div>
                <motion.div
                    ref={ref6}
                    initial={{ opacity: 0, y: 50, x: -100 }}
                    animate={isVisible6 && { opacity: 1, y: 0, x: 0 }}
                    transition={{ duration: 1 }}
                    onClick={() => openPopupWithSection('unterricht')}
                    className="hover:cursor-pointer order-5 sm:order-6 p-4 relative flex justify-center items-center  border-8 border-blue_dark rounded-2xl">
                    <img
                        className="w-full h-full absolute top-0 left-0 object-cover brightness-75 rounded"
                        src={
                            API.img +
                            content?.unterricht?.bild?.data?.attributes?.url
                        }
                        alt=""
                    />
                    <div className="font-permanentMarker text-2xl sm:text-3xl md:text-4xl z-10 text-primary text-center">
                        {content?.unterricht?.heading}
                    </div>
                </motion.div>
            </div>
            {popupOpen && popupContent && (
                <PopupLeitbild
                    onClose={closePopup}
                    contentSection={popupContent}
                />
            )}
        </div>
    )
}

export default SectionLeitbild
