import React from 'react'
import SectionHeading from './SectionHeading'
import { ImageType } from '../../types/types'
import { API } from '../../data/constants'
import { useOnScreen } from '../common/smoothInEffektHook'
import { motion } from 'framer-motion'

type verpflichtungData = {
    content?: {
        heading: string
        icons: {
            id: number
            Icon: {
                data: ImageType
            }
            activity: string
        }[]
    }
    id?: string
}

function SectionVerpflichtungAG({ content, id }: verpflichtungData) {
    const [ref1, isVisible1] = useOnScreen({ threshold: 0.5 })

    return (
        <div id={id} className="max-w-page mx-auto px-8 mb-8">
            <SectionHeading heading={content?.heading} />
            <motion.div
                ref={ref1}
                initial={{ opacity: 0, y: 50 }}
                animate={isVisible1 && { opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                className="flex flex-wrap gap-2 justify-center items-center">
                {content?.icons.map((ag) => (
                    <div
                        key={ag.id}
                        className="flex flex-col gap-4 items-center justify-center basis-1/3 sm:basis-1/5">
                        {ag?.Icon?.data?.attributes?.url ? (
                            <img
                                src={API.img + ag?.Icon?.data?.attributes?.url}
                                alt=""
                                className="w-20 h-20"
                            />
                        ) : (
                            <div className="w-20 h-20 bg-orange_dark rounded-full flex items-end justify-center">
                                <p className="font-bold font-permanentMarker text-5xl text-white">
                                    {ag.activity[0]}
                                </p>
                            </div>
                        )}
                        <div className="text-center font-permanentMarker ">
                            {ag?.activity}
                        </div>
                    </div>
                ))}
            </motion.div>
        </div>
    )
}

export default SectionVerpflichtungAG
