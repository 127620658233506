// /api/footer

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '../data/constants';
import { ImageType } from '../types/types';


type FooterDataType = {
    mail: string;
    phone_number: string;
    logo_white: {
        data: ImageType
    }
}

export const useFooterData = () => {
    return useQuery<FooterDataType, Error>({
        queryKey: ['footerData'],
        queryFn: () => axios.get(`${API.general}/footer?populate=*`)
            .then(res => res.data.data.attributes)
    });
};
