import React from 'react'
import SectionHeading from './SectionHeading'
import { HashLink } from 'react-router-hash-link'
import { motion } from 'framer-motion'
import { useOnScreen } from '../common/smoothInEffektHook'

function SectionContact() {
    const [ref1, isVisible1] = useOnScreen({ threshold: 0.5 })

    return (
        <div className="max-w-page mx-auto px-8 mb-28">
            <SectionHeading heading="Kontakt" />
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8">
                <motion.p
                    ref={ref1}
                    initial={{ opacity: 0, x: -100 }}
                    animate={isVisible1 && { opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    className=" text-content text-center sm:text-left">
                    Benötigen Sie Hilfe oder haben Sie Fragen? Wir sind hier, um
                    Ihnen zu helfen! Zögern Sie nicht, uns zu kontaktieren.
                </motion.p>
                <HashLink
                    to="/kontakt"
                    className="bg-orange_dark py-2 text-primary font-permanentMarker rounded-2xl flex justify-center items-center text-xl tracking-wider hover:bg-orange_light">
                    Kontakt aufnehmen {'>>'}
                </HashLink>
            </div>
        </div>
    )
}

export default SectionContact
