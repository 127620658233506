import { MdOutlineCancel } from 'react-icons/md'
import SectionHeading from './SectionHeading'
import { ContentSection } from '../../types/types'
import { API } from '../../data/constants'

type PopupLeitbildProps = {
    onClose: () => void
    contentSection?: ContentSection
}

function PopupLeitbild({ onClose, contentSection }: PopupLeitbildProps) {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 overflow-y-auto">
            <div className="bg-primary px-8 py-7 md:px-24 md:py-16 rounded-lg max-w-page w-full relative">
                <button
                    className="absolute top-0 right-0 text-4xl text-blue_dark lg:active:animate-ping hover:duration-300 hover:rotate-90 mt-4 mr-4"
                    onClick={onClose}>
                    <MdOutlineCancel className="block" />
                </button>
                <div className=" mt-4 flex flex-col relative overflow-y-auto max-h-[80vh]">
                    <SectionHeading heading={contentSection?.heading} />
                    <div className="px-10 flex flex-col items-center">
                        <img
                            className="object-cover h-80 w-auto"
                            src={
                                API.img +
                                contentSection?.bild?.data?.attributes?.url
                            }
                            alt="Bild"
                        />
                        <p
                            className="text-center mt-8"
                            style={{ lineHeight: '1.5' }}>
                            {contentSection?.text}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PopupLeitbild
