import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '../data/constants';
import { ImageType } from '../types/types';

type ImpressumDataType = {
    element: {
        id: number;
        heading: string;
        content: string;
    }[]
    hero:{
        headline:string,
        hero_img:{
            data: ImageType
        }
    }
}


export const useImpressumData = () => {
    return useQuery<ImpressumDataType, Error>({
        queryKey: ['impressumData'],
        queryFn: () => axios.get(`${API.general}/impressum?populate=element&populate=hero.hero_img`)
            .then(res => res.data.data.attributes)
    });
};
