import Loading from '../../components/common/Loading'
import Hero from '../../components/custom/Hero'
import SectionHeading from '../../components/custom/SectionHeading'
import { useImpressumData } from '../../hooks/useImpressumData'
import ErrorPage from '../404/ErrorPage'

export default function Impressum() {
    const {
        data: impressumData,
        isLoading: isLoadingImpressumData,
        error: errorImpressumData,
    } = useImpressumData()

    return (
        <>
            {isLoadingImpressumData && <Loading />}
            {errorImpressumData && <ErrorPage />}
            {!isLoadingImpressumData &&
                !errorImpressumData &&
                impressumData && (
                    <>
                        <Hero
                            pageTitle={impressumData?.hero?.headline}
                            heroImg={
                                impressumData?.hero?.hero_img.data?.attributes
                                    ?.url
                            }
                        />
                        <div className="max-w-xl mx-auto px-8">
                            {impressumData?.element?.map((item) => (
                                <div key={item.id} className="mb-16">
                                    <SectionHeading heading={item.heading} />
                                    <div className="max-w-xs mx-auto">
                                        {item.content
                                            .split('\n')
                                            .map((line, index) => (
                                                <p
                                                    key={index}
                                                    className="text-center">
                                                    {line}
                                                </p>
                                            ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}
        </>
    )
}
