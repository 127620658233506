import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '../data/constants';

export const useRegisterData = () => {
    return useQuery<any, Error>({
        queryKey: ['registerData'],
        queryFn: () => axios.get(`${API.general}/transparenzregister?populate=content`)
            .then(res => res.data.data.attributes)
    });
};

// export const useRegisterHeroData = () => {
//     return useQuery<heroType, Error>({
//         queryKey: ['registerHeroData'],
//         queryFn: () => axios.get(`${API.general}/transparenzregister?populate[hero][populate]=*`)
//             .then(res => res.data.data.attributes)
//     });
// };
