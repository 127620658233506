import { ContactDataType } from '../../types/types'
import SectionHeading from './SectionHeading'
import { ReactComponent as MailIcon } from '../../assets/material-symbols_mail.svg'
import { ReactComponent as PhoneIcon } from '../../assets/ic_baseline-phone.svg'

type SectionContactDataType = {
    content?: ContactDataType
}

function SectionContactData({ content }: SectionContactDataType) {
    return (
        <div className="max-w-page mx-auto my-16">
            <SectionHeading heading="Kontaktdaten" />
            <div className="flex flex-col sm:flex-row justify-center gap-10 sm:gap-10">
                {' '}
                {/* Adjust the gap as needed */}
                <a
                    href={`mailto:${content?.contact_data?.mail}`}
                    className="flex flex-col items-center space-y-3 sm:space-y-6">
                    <MailIcon />
                    <p className="font-medium text-center">
                        {content?.contact_data?.mail}
                    </p>
                </a>
                <a
                    href={`tel:${content?.contact_data?.phone_number}`}
                    className="flex flex-col items-center space-y-3 sm:space-y-6 px-16">
                    <PhoneIcon />
                    <p className="font-medium text-center">
                        {content?.contact_data?.phone_number}
                    </p>
                </a>
            </div>
        </div>
    )
}

export default SectionContactData
