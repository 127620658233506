import { HashLink } from 'react-router-hash-link'
import { useFooterData } from '../../hooks/useFooterData'
import { API } from '../../data/constants'

export default function Footer() {
    const {
        data: footerData,
        isLoading: isLoadingFooterData,
        error: errorFooterData,
    } = useFooterData()

    if (isLoadingFooterData) {
        return <div></div>
    }

    if (errorFooterData) {
        return <div>Error loading data</div>
    }

    return (
        <div className="w-full  bg-content text-primary px-8 py-12">
            <div className="max-w-page mx-auto">
                <div className="grid gap-8 grid-cols-1 md:grid-rows-1 md:grid-cols-3 ">
                    <div className="hidden sm:block">
                        <img
                            src={
                                API.img +
                                footerData?.logo_white?.data?.attributes?.url
                            }
                            alt={footerData?.logo_white?.data?.attributes?.name}
                            className="w-[250px]"
                        />
                    </div>
                    <div className="space-y-2 ">
                        <div className="text-xl font-medium mb-12"></div>
                        <HashLink to={'/impressum#_'}>
                            <p className="font-light hover:underline">
                                Impressum
                            </p>
                        </HashLink>
                        <HashLink to={'/datenschutz#_'}>
                            <p className="font-light hover:underline">
                                Datenschutz
                            </p>
                        </HashLink>
                        <HashLink to={'/transparenzregister#_'}>
                            <p className="font-light hover:underline">
                                Transparenzregister
                            </p>
                        </HashLink>
                    </div>
                    <div className="space-y-2 ">
                        <h1 className="text-xl font-medium mb-6">
                            Kontaktieren Sie Uns
                        </h1>
                        <div className="flex md:justify-between">
                            <p className="font-light w-20">Telefon:</p>
                            <a
                                href={`tel:${footerData?.phone_number}`}
                                className="font-medium  hover:underline">
                                {footerData?.phone_number}
                            </a>
                        </div>
                        <div className="flex md:justify-between ">
                            <p className="font-light w-20">E-Mail:</p>
                            <a
                                href={`mailto:${footerData?.mail}`}
                                className="font-medium hover:underline">
                                {footerData?.mail}
                            </a>
                        </div>
                        <div className="flex md:justify-between ">
                            <p className="font-light w-20">Fotos von:</p>
                            <HashLink
                                to="http://www.artworkphotography.de/"
                                target="_blank"
                                className="font-medium hover:underline">
                                Artwork Photography
                            </HashLink>
                        </div>
                    </div>
                </div>
                <hr className="my-12 text-underline border-1" />
                <h4 className=" w-fit mx-auto font-thin text-base ">
                    Bebel Grundschule Zwickau | powered by{' '}
                    <HashLink
                        className="text-white font-medium"
                        target="_blank"
                        to="https://www.wicode.io">
                        Wicode GmbH
                    </HashLink>
                </h4>
            </div>
        </div>
    )
}
