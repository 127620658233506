import SectionHeading from './SectionHeading'
import { ImageType } from '../../types/types'
import { API } from '../../data/constants'
import { useEffect, useRef } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css' // Import default Splide CSS
import { MdArrowBackIos, MdArrowForwardIos } from 'react-icons/md'
import axios from 'axios'
import { motion } from 'framer-motion'
import { useOnScreen } from '../common/smoothInEffektHook'
import { BlocksContent, BlocksRenderer } from '@strapi/blocks-react-renderer'

type schulsozData = {
    content?: {
        heading: string
        bild: {
            data: ImageType
        }
        name: string
        text: BlocksContent
        carousel: {
            data: ImageType[]
        }
        Download_Button_Text: string
        PDF: {
            data: {
                attributes: {
                    name: string
                    url: string
                }
            }
        }
    }
}

function SectionSchulsoz({ content }: schulsozData) {
    const splideRef = useRef<any>(null)
    const [ref1, isVisible1] = useOnScreen()
    const [ref2, isVisible2] = useOnScreen()
    const [ref3, isVisible3] = useOnScreen()

    useEffect(() => {
        if (splideRef.current) {
            splideRef.current.splide.refresh()
        }
    }, [content?.carousel?.data])

    const handlePrev = () => {
        if (splideRef.current) {
            splideRef?.current?.splide.go('-1') // Go to the previous slide
        }
    }

    const handleNext = () => {
        if (splideRef?.current) {
            splideRef?.current?.splide.go('+1') // Go to the next slide
        }
    }

    const handleDownload = async (pdfLink: string, fileName: string) => {
        axios
            .get(pdfLink, {
                responseType: 'blob',
            })
            .then((res) => {
                const pdfUrl = URL.createObjectURL(res.data)
                const link = document.createElement('a')
                link.href = pdfUrl
                link.download = fileName
                link.click()
            })
            .catch((err) => console.log(err))
    }

    return (
        <div id="schulsoz" className="px-8 pb-8 ">
            <SectionHeading heading={content?.heading} />
            <div className="max-w-page mx-auto grid grid-cols-1 sm:grid-cols-2 items-center justify-center mb-16 sm:mb-32 gap-6 md:gap-10 md:px-24">
                <img
                    className=" max-h-[500px] mx-auto sm:mx-0 sm:justify-self-end"
                    src={API.img + content?.bild?.data?.attributes?.url}
                    alt=""
                />
                <div className="text-center sm:text-left">
                    <motion.div
                        ref={ref1}
                        initial={{ opacity: 0, x: 150 }}
                        animate={isVisible1 && { opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        className="font-permanentMarker text-2xl sm:text-4xl text-content pb-4">
                        {content?.name}
                    </motion.div>
                    <motion.div
                        ref={ref2}
                        initial={{ opacity: 0, x: 150 }}
                        animate={isVisible2 && { opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}>
                            <BlocksRenderer content={content?.text ?? []}/>
                    </motion.div>
                    <motion.div
                        ref={ref3}
                        initial={{ opacity: 0, x: 150 }}
                        animate={isVisible3 && { opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        onClick={() =>
                            handleDownload(
                                API.img + content?.PDF.data.attributes.url,
                                content?.PDF.data.attributes.name ??
                                    'Dokument.pdf'
                            )
                        }
                        className="btn bg-orange_dark border-none hover:bg-orange_light">
                        {content?.Download_Button_Text}
                    </motion.div>
                </div>
            </div>
            <div className="max-w-page flex mx-auto justify-around px-4 md:px-0 ">
                <button
                    className="hidden md:block text-6xl text-blue_dark p-5"
                    aria-label="Previous"
                    onClick={handlePrev}>
                    <MdArrowBackIos />
                </button>

                <div className="max-w-page bg-primary py-0 rounded-lg md:rounded-none">
                    <Splide
                        options={{
                            type: 'slide',
                            perPage: 3,
                            breakpoints: {
                                768: {
                                    perPage: 1,
                                },
                            },
                            pagination: true,
                            navigation: true,
                            arrows: false,
                            gap: '1rem',
                            onMounted: () => {
                                if (splideRef.current) {
                                    splideRef.current.splide.refresh()
                                }
                            },
                        }}
                        ref={splideRef}
                        aria-label="My Favorite Images">
                        {content?.carousel?.data.map((image) => (
                            <SplideSlide key={image.id}>
                                <img
                                    className="w-full h-full object-cover"
                                    src={API.img + image.attributes.url}
                                    alt={image.attributes.name}
                                />
                            </SplideSlide>
                        ))}
                    </Splide>
                </div>

                <button
                    className="hidden md:block text-6xl text-blue_dark p-5"
                    aria-label="Next"
                    onClick={handleNext}>
                    <MdArrowForwardIos />
                </button>
            </div>
        </div>
    )
}

export default SectionSchulsoz
