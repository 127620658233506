import Loading from '../../components/common/Loading'
import Hero from '../../components/custom/Hero'
import { useDatenschutzData } from '../../hooks/useDatenschutzData'
import {
    BlocksRenderer,
    type BlocksContent,
} from '@strapi/blocks-react-renderer'
import ErrorPage from '../404/ErrorPage'

export default function Datenschutz() {
    const {
        data: datenschutzData,
        isLoading: isLoadingDatenschutzData,
        error: errorDatenschutzData,
    } = useDatenschutzData()

    const content: BlocksContent = datenschutzData?.content

    return (
        <>
            {isLoadingDatenschutzData && <Loading />}
            {errorDatenschutzData && <ErrorPage />}
            {!isLoadingDatenschutzData &&
                !errorDatenschutzData &&
                datenschutzData && (
                    <>
                        {/* <Hero pageTitle="Datenschutz" /> */}
                        <div className="max-w-3xl px-8 py-6 mx-auto mt-24">
                            <BlocksRenderer content={content ?? []} />
                        </div>
                    </>
                )}
        </>
    )
}
