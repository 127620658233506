import { useLocation } from 'react-router-dom'
import { ReactComponent as WellenOben } from '../../assets/wellen_oben.svg'
import { HeroHomepageType } from '../../types/types'
import { API } from '../../data/constants'

function Hero({ pageTitle, subheading, heroImg }: HeroHomepageType) {
    const location = useLocation()
    // adding different attributes for different pages
    const isHomePage = location.pathname === '/'
    // Determine if subheading is provided and not empty
    const hasSubheading = isHomePage && subheading && subheading.trim() !== ''

    return (
        <div
            id="_"
            className={`relative bg-cover bg-center ${
                isHomePage
                    ? 'h-[300px] lg:h-[730px] '
                    : 'h-[300px] lg:h-[500px]'
            }`}
            style={{ backgroundImage: `url(${API.img + heroImg})` }}>
            <div className="absolute inset-0 bg-black bg-opacity-30"></div>

            {/* Centered Text Div */}
            <div
                className={`absolute inset-0 flex items-center justify-center flex-col text-white space-y-4 ${
                    hasSubheading ? 'h-[90%]' : 'h-[82%]'
                }`}>
                <div
                    className={`text-center  font-bold font-permanentMarker max-w-2xl mx-auto ${
                        isHomePage
                            ? 'text-4xl sm:text-5xl lg:text-8xl'
                            : 'text-4xl sm:text-5xl lg:text-7xl'
                    }`}>
                    {pageTitle}
                </div>
                {hasSubheading && (
                    <div className="text-2xl sm:text-3xl lg:text-5xl font-extralight max-w-xl mx-auto">
                        {subheading}
                    </div>
                )}
            </div>

            <div className="absolute bottom-0 w-full">
                <WellenOben />
            </div>
        </div>
    )
}

export default Hero
