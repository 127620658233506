//aktuelle?populate[elternzeitung][populate][articles][populate]=image&populate[schuelerzeitung][populate][articles][populate]=image
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '../data/constants';

import { AktuellesType } from '../types/types';



export const useAktuellesData = () => {
    return useQuery<AktuellesType, Error>({
        queryKey: ['aktuellesData'],
        queryFn: () => axios.get(`${API.general}/aktuelle?populate[elternzeitung][populate][articles][populate]=image&populate[schuelerzeitung][populate][articles][populate]=image&populate[hero][populate]=hero_img`)
            .then(res => res.data.data.attributes)
    });
};
