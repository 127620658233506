import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { API } from '../data/constants';
import { TeamType} from '../types/types';

export const useTeamData = () => {
  return useQuery<TeamType, Error>({
    queryKey: ['teamData'],
    queryFn: () => axios.get(`${API.general}/unsere-schule?populate[schulleitung][populate]=*&populate[sekretariat][populate]=*&populate[schulsozialarbeit][populate]=*&populate[Teachers][populate]=*&populate[hero][populate]=*`)
                        .then(res => res.data.data.attributes)
  });
};
