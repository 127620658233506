import { ReactNode } from 'react'
import { Helmet } from 'react-helmet'

export type BaseLayoutType = {
    title: string
    children: ReactNode
}

export default function BaseLayout({ title, children }: BaseLayoutType) {
    return (
        <section className="bg-primary text-content">
            <Helmet>
                <title>Bebelschule | {title}</title>
            </Helmet>
            {children}
        </section>
    )
}
