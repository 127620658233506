//Components
import Hero from '../../components/custom/Hero'
import SectionLeitbild from '../../components/custom/SectionLeitbild'
import SectionUnterricht from '../../components/custom/SectionUnterricht'

// GTA Comps
import SectionVerpflichtungAG from '../../components/custom/SectionVerpflichtungAG'
import SectionHeading from '../../components/custom/SectionHeading'
import SectionGTAPlan from '../../components/custom/SectionGTAPlan'

//Strapi
import { useUnsereSchuleData } from '../../hooks/useUnsereSchuleData'
import Loading from '../../components/common/Loading'
import ErrorPage from '../404/ErrorPage'

function UnsereSchule() {
    const {
        data: unsereSchuleData,
        isLoading: isLoadingUnsereSchuleData,
        error: errorUnsereSchuleData,
    } = useUnsereSchuleData()

    return (
        <>
            {isLoadingUnsereSchuleData && <Loading />}
            {errorUnsereSchuleData && <ErrorPage />}
            {!isLoadingUnsereSchuleData &&
                !errorUnsereSchuleData &&
                unsereSchuleData && (
                    <>
                        <Hero
                            pageTitle={unsereSchuleData?.hero.headline}
                            heroImg={
                                unsereSchuleData?.hero.hero_img.data.attributes
                                    .url
                            }
                        />
                        <SectionLeitbild content={unsereSchuleData?.leitbild} />
                        <SectionUnterricht
                            unterricht={unsereSchuleData?.unterricht}
                        />
                        <SectionVerpflichtungAG
                            id="gta"
                            content={unsereSchuleData?.verpflichtungsangebote}
                        />
                        <SectionVerpflichtungAG
                            content={unsereSchuleData?.nachmittagsangebote}
                        />
                        <SectionHeading
                            heading={unsereSchuleData?.heading_zeiten}
                        />
                        <SectionGTAPlan content={unsereSchuleData?.montag} />
                        <SectionGTAPlan content={unsereSchuleData?.dienstag} />
                        <SectionGTAPlan content={unsereSchuleData?.mittwoch} />
                        <SectionGTAPlan
                            content={unsereSchuleData?.donnerstag}
                        />
                        <SectionGTAPlan content={unsereSchuleData?.freitag} />
                    </>
                )}
        </>
    )
}

export default UnsereSchule
